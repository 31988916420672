import * as React from "react";
import i18next from '../i18n.js'

import HomeContact from "../components/homeContact";
import Layout from "../components/layout";

import * as Styles from "../styles/layout.module.scss";

const Kontakt = ({ pageContext }) => {
  var translationContext = { locale: "en",
                             defaultMenu: true };
  var { t } = i18next
  return (
    <Layout
      title="Kontakt"
      translationContext={translationContext}
      pageContext={pageContext}
    >
      <div class={Styles.singleTopicLayout}>        
        <main>
          <h1>{t('headerContact')}</h1>
          <p>
            Brandeis Consulting GmbH <br/>
            Rheindammstr. 5 <br/>
            68163 Mannheim <br/>
          </p>
          <HomeContact locale={pageContext.locale} />
        </main>
      </div>
    </Layout>
  );
};

export default Kontakt;